@font-face {
  font-family: 'Inter';
  font-weight: 100 900;
  font-display: swap;
  font-named-instance: 'Regular';
  src: url(../fonts/Inter-VariableFont.ttf) format('truetype');
}

@font-face {
  font-family: 'Hobeaux';
  font-weight: 900;
  src: url(../fonts/hobeaux-black.ttf) format('truetype');
}
